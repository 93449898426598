<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card-actions
      ref="allCommissionsListActionCard"
      title="All Commissions list"
      action-refresh
      action-collapse
      @refresh="loadCommissionsFunction"
    >

      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="allCommissionsTable"
        class="position-relative"
        :items="commissionsList"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        show-empty
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
      >
        <template #row-details="row">
          <b-table
            ref="allCommissionsGroupTable"
            class="position-relative"
            :items="row.item.broker.dashboardTotalCommission"
            responsive
            hover
            :fields="groupCommissionFields"
            primary-key="id"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            empty-text="No matching records found"
          >
            <template #row-details="row">
              <b-table
                ref="refCommissionListTable"
                class="position-relative"
                :items="row.item.commissions"
                table-variant="primary"
                head-variant="light"
                small
                :fields="subTableFields"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
              >
                <template #cell(lead.name)="data">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="35"
                        :src="data.item.lead.avatar"
                        :text="avatarText(data.item.lead.name)"
                        :to="{ name: 'user_data', params: { id: data.item.lead.id } }"
                      />
                    </template>
                    <b-link
                      :to="{ name: 'user_data', params: { id: data.item.lead.id } }"
                      class="font-weight-bold d-block text-nowrap text-dark"
                      style="text-decoration: underline"
                    >
                      {{ data.item.lead.name }}
                    </b-link>
                    <small class="text-muted">{{ data.item.lead.type }}</small>
                  </b-media>
                </template>
                <template #cell(collected)="data">
                  <b-badge
                    pill
                    :variant="data.item.collected ? 'light-success' : 'light-danger'"
                  >
                    <feather-icon
                      :icon="data.item.collected ? 'CheckIcon' : 'XIcon'"
                      class="mr-25"
                    />
                    <span>{{ data.item.collected ? 'Yes' : 'No' }}</span>
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content v-if="!data.item.collected">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="checkCommission(data.item.id, data.item.groupCommissionID, row.item.commissions)"
                    >
                      <feather-icon icon="DollarSignIcon" />
                      <span class="align-middle ml-50">Collect</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-if="data.item.pdf_url"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                size="sm"
                @click="downloadPdf(data.item.pdf_url.data)"
              >
                PDF
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="ml-1"
                size="sm"
                @click="data.toggleDetails"
              >
                {{ data.detailsShowing ? 'Hide' : 'View' }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1"
                size="sm"
                @click="deleteCommissionGroup(data.item.id)"
              >
                Delete
              </b-button>

              <b-button
                v-if="data.item.nonCollectedCommissionsCount > 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                class="ml-1"
                size="sm"
                @click="collectAllCommissionsFunction(data.item.id)"
              >
                Collect All
              </b-button>
            </template>
          </b-table>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="35"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                :to="{ name: 'user_data', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'user_data', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-dark"
              style="text-decoration: underline"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.broker.type }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(broker.commissions.length)="data">
          {{ data.item.broker.commissions.length }}
        </template>

        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="ml-1"
            size="sm"
            @click="data.toggleDetails"
          >
            {{ data.detailsShowing ? 'Hide' : 'View' }}
          </b-button>
          <b-button
            v-if="data.item.broker.notCollected"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="ml-1"
            size="sm"
            @click="collectAllCommissionsForBrokerFunction(data.item)"
          >
            Collect All
          </b-button>
        </template>

        <template #cell(collected)="data">
          <b-badge
            pill
            :variant="data.item.broker.notCollected ? 'light-danger' : 'light-success'"
          >
            <feather-icon
              :icon="data.item.broker.notCollected ? 'XIcon' : 'CheckIcon'"
              class="mr-25"
            />
            <span>{{ data.item.broker.notCollected ? 'No' : 'Yes' }}</span>
          </b-badge>
        </template>

      </b-table>
      <!--      <b-row>
        &lt;!&ndash; Pagination &ndash;&gt;
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>-->

      <!-- modal vertical center -->
      <b-modal
        id="modal-center"
        v-model="modalShow"
        centered
        no-close-on-backdrop
        content-class="shadow"
        hide-backdrop
        title="Confirm"
      >
        <b-card-text>
          There are other commissions on the same date with the same broker that are also not collected. Would you like to convert all commissions on the same date as collected?
        </b-card-text>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-left mb-0" />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="float-right ml-1"
              @click="modalShow = false"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-dark"
              size="sm"
              class="float-right ml-1"
              @click="editCommissionFunction(selectedCommissionID)"
            >
              No
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right ml-1"
              @click="collectAllCommissionsFunction"
            >
              Yes
            </b-button>
          </div>
        </template>
      </b-modal>

    </b-card-actions>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable, BAvatar, BCol, BRow, BLink, BMedia, BDropdown, BDropdownItem, BBadge, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AllCommissions',
  components: {
    BTable,
    BAvatar,
    BCol,
    BRow,
    BLink,
    BMedia,
    // BPagination,
    BCardActions,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BOverlay,
  },

  data() {
    return {
      modalShow: false,
      perPage: 5,
      pageOptions: [1, 5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      selectedCommissionID: null,
      selectedGroupCommissionID: null,
      fields: [
        { key: 'name', label: 'BROKER', sortable: true },
        { key: 'mobile', label: 'MOBILE', sortable: true },
        { key: 'broker.commissions.length', label: 'COMMISSIONS', sortable: true },
        { key: 'collected', label: 'COLLECTED', sortable: true },
        { key: 'actions' },
      ],
      groupCommissionFields: [
        { key: 'date', label: 'DATE' },
        { key: 'commissionsCount', label: 'COMMISSIONS', sortable: true },
        { key: 'nonCollectedCommissionsCount', label: 'NOT COLLECTED', sortable: true },
        { key: 'actions' },
      ],
      subTableFields: [
        { key: 'lead.name', label: 'LEAD', sortable: true },
        { key: 'lead.product', label: 'PRODUCT', sortable: true },
        {
          key: 'commission', label: 'COMMISSION', tdClass: 'cellClass', sortable: true,
        },
        { key: 'collectionDate', label: 'DATE', sortable: true },
        { key: 'collected', label: 'COLLECTED', sortable: true },
        { key: 'actions' },
      ],
      showLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      commissionsList: 'commissions/commissionsList',
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    this.showLoading = true
    await this.loadCommissionsFunction()
    this.totalRows = this.commissionsList.length
    this.showLoading = false
  },
  methods: {
    ...mapActions({
      loadAllCommissions: 'get',
      collectAllCommissions: 'get',
      editGroupCommission: 'edit',
      delete: 'delete',
    }),
    downloadPdf(url) {
      window.open(url, '_blank')
    },
    async loadCommissionsFunction() {
      this.showLoading = true
      await this.loadAllCommissions({
        storeName: 'commissions',
        url: '/admin/commissions',
        commitUrl: 'commissions/SET_COMMISSIONS',
      })
      this.showLoading = false
      this.$refs.allCommissionsListActionCard.showLoading = false
    },
    checkCommission(commissionID, groupCommissionID, commissionsList) {
      this.selectedGroupCommissionID = groupCommissionID
      this.selectedCommissionID = commissionID
      const filterResult = commissionsList.filter(commission => commission.collected === false)
      if (filterResult.length > 1) {
        this.modalShow = true
      } else {
        this.editCommissionFunction(this.selectedCommissionID)
      }
    },
    async deleteCommissionGroup(groupCommissionID) {
      this.showLoading = true
      await this.delete({
        url: `admin/commissions/${groupCommissionID}`,
        storeName: 'commissions',
      })
        .then(async () => {
          this.showLoading = false
          this.modalShow = false
          await this.loadCommissionsFunction()
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Deleted Successfully',
            },
          })
        })
        .catch(error => {
          this.showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    async collectAllCommissionsForBrokerFunction(brokerData) {
      const commissions = brokerData.broker.commissions.map(commission => commission.id)
      await commissions.forEach(commissionId => {
        this.collectAllCommissionsFunction(commissionId, false)
      })
      await this.loadCommissionsFunction()
    },
    async collectAllCommissionsFunction(groupCommissionID, loadCommissions = true) {
      if (groupCommissionID !== null) {
        this.selectedGroupCommissionID = groupCommissionID
      }
      await this.collectAllCommissions({
        url: `admin/commissions/collect/group/${this.selectedGroupCommissionID}`,
        storeName: 'commissions',
      })
        .then(async () => {
          this.modalShow = false
          if (loadCommissions) { await this.loadCommissionsFunction() }
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Collected Successfully',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    async editCommissionFunction(commissionID) {
      await this.editGroupCommission({
        form: { commission: { collected: true }, type: 'commission_collected' },
        url: `admin/commissions/${commissionID}`,
        storeName: 'commissions',
      }).then(async () => {
        this.modalShow = false
        await this.loadCommissionsFunction()
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Updated Successfully',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
    /* cellClass(item, type) {
      // item.toggleDetails()
      console.log(item)
      console.log(type)
    }, */
  },
}
</script>

<style scoped>

</style>
